import { CrmAppConfiguration } from 'common-module/core/types';
import { CrmFilterConfig } from 'common-module/filter';
import {
  CrmLayoutCollapseComponent,
  CrmLayoutLogoComponent,
  CrmLayoutMenuComponent,
  CrmLayoutProfileComponent,
  crmLayoutSidebarOnly,
} from 'common-module/layout-v2';
import { CrmTableConfig } from 'common-module/table';

import { environment } from '../environments/environment';

import { LayoutMenuProvider } from './shared/crm/layout/layout-menu.provider';
import { LayoutProfileProvider } from './shared/crm/layout/layout-profile.provider';
import { MenuItem } from './shared/model/menu-item';

export const appConfig: CrmAppConfiguration = {
  environment,
  modules: {
    layoutV2: {
      siderLeft: {
        ...crmLayoutSidebarOnly.siderLeft,
        fragments: [
          {
            key: 'logo',
            content: CrmLayoutLogoComponent,
          },
          {
            key: 'menu',
            content: CrmLayoutMenuComponent,
            provider: LayoutMenuProvider,
          },
          {
            key: 'collapse',
            content: CrmLayoutCollapseComponent,
          },
          {
            key: 'profile',
            content: CrmLayoutProfileComponent,
            provider: LayoutProfileProvider,
          },
        ],
      },
      logo: {
        defaultLogoPath: '/assets/images/boge_black.svg',
        smallLogoPath: '/assets/images/boge_small.jfif',
      },
      menu: {
        items: [
          {
            key: 'layout.menu.news',
            icon: 'read',
            routeKey: 'news',
            permission: 'news',
          },
          {
            key: 'layout.menu.menu',
            icon: 'icons:restaurant',
            routeKey: 'menu',
            permission: 'meal',
          },
          {
            key: 'layout.menu.contacts',
            icon: 'icons:contact',
            routeKey: 'contacts',
            permission: 'contacts',
          },
          {
            key: 'layout.menu.benefits',
            icon: 'star',
            routeKey: 'benefits',
            permission: 'benefit',
          },
          {
            key: 'layout.menu.collectiveAgreement',
            icon: 'file-text',
            routeKey: 'collective-agreement',
            permission: 'collective-agreement',
          },
          {
            key: 'layout.menu.jobs',
            icon: 'icons:career',
            routeKey: 'jobs',
            permission: 'job',
          },
          {
            key: 'layout.menu.questions',
            icon: 'notification',
            routeKey: 'questions',
            permission: 'ticket',
          },
          {
            key: 'layout.menu.users',
            icon: 'user',
            routeKey: 'users',
            role: ['admin'],
          },
        ] as MenuItem[],
      },
      profile: {
        dropdownTrigger: 'click',
        dropdownPlacement: 'right',
        dropdownIcon: 'down',
        dropdownIconVisible: 'right',
      },
    },
    filter: {
      filters: [],
      actions: {
        clear: {
          enabled: true,
          style: { textTransform: 'uppercase' },
        },
      },
    } as CrmFilterConfig,
    table: {
      component: {
        emptyState: {
          title: 'crm.table.noData',
        },
      },
    } as CrmTableConfig,
  },
};
