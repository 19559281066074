import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CrmLayoutV2Component } from 'common-module/layout-v2';

@Component({
  standalone: true,
  selector: 'app-layout-outlet',
  template: `
    <crm-layout-v2>
      <div class="layout-content">
        <router-outlet></router-outlet>
      </div>
      <div class="layout-footer">
        <div class="layout-brand">
          Made by
          <a
            href="https://www.touch4it.com/"
            target="_blank"
            class="layout-brand-logo"
          >
            <img src="/assets/images/touch4it.svg" alt="touch4it.com" />
          </a>
        </div>
      </div>
    </crm-layout-v2>
  `,
  styles: [
    `
      .layout-content {
        flex: 1;
        min-height: 0;
      }

      .layout-footer {
        padding: 16px;
        text-align: center;
      }

      .layout-brand-logo {
        position: relative;
        top: -1px;
      }
    `,
  ],
  imports: [CrmLayoutV2Component, RouterOutlet],
})
export class LayoutOutletComponent {}
