import { CrmMenuItem } from 'common-module/menu';

import { getUserRole } from '../crm/user/helpers/get-user-role';
import { User, UserPermissions } from '../crm/user/user';

export interface MenuItem extends CrmMenuItem {
  permission: keyof UserPermissions;
}

export const isAllowedMenuItem = (menuItem: MenuItem, user: User): boolean => {
  const permissions = user.permissions ?? {};
  const userRole = getUserRole(user);

  if (menuItem.role) {
    if (Array.isArray(menuItem.role)) {
      return menuItem.role.includes(userRole);
    } else {
      return menuItem.role === userRole;
    }
  }

  if (menuItem.permission) {
    return permissions[menuItem.permission]?.display ?? false;
  }

  return true;
};
