import { inject, Injectable } from '@angular/core';
import { CrmLayoutMenuProvider } from 'common-module/layout-v2';
import { map, Observable } from 'rxjs';

import { isAllowedMenuItem, MenuItem } from '../../model/menu-item';
import { isUserAdmin } from '../user/helpers/is-user-admin';
import { UserService } from '../user/user.service';

@Injectable({ providedIn: 'root' })
export class LayoutMenuProvider extends CrmLayoutMenuProvider {
  protected userService = inject(UserService);

  override items$(): Observable<MenuItem[]> {
    return this.userService.user$.pipe(
      map((user) => {
        const menuItems = this.config.items as MenuItem[];

        if (isUserAdmin(user)) {
          return menuItems;
        }

        return menuItems.filter((menuItem: MenuItem) => {
          return isAllowedMenuItem(menuItem, user);
        });
      }),
    );
  }
}
