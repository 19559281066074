// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from './environment.model';
import { APP_PROJECT_NAME, APP_VERSION } from './variables';

export const environment: Environment = {
  production: false,
  env: 'dev',
  version: APP_VERSION,
  projectName: APP_PROJECT_NAME,
  serverUrl: 'https://api.admin.emploportal.touch4dev.net',
  basicAuth: 'ZW1wbG95ZWVhcHA6VlRCRDVtN1VYM25xdnBZRA==',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
