import sk from '@angular/common/locales/sk';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import {
  CrmAuthModule,
  CrmAuthResetPasswordSuccessProviderImpl,
} from 'common-module/auth';
import { CrmAppConfig } from 'common-module/core';
import { CrmEditorModule } from 'common-module/editor';
import { CrmEndpointModule } from 'common-module/endpoint';
import { CrmMessageModule } from 'common-module/message';
import { CrmModalModule } from 'common-module/modal';
import { CrmTranslateModule } from 'common-module/translate';
import { CrmUserService } from 'common-module/user';
import dfnsSk from 'date-fns/locale/sk';
import { sk_SK } from 'ng-zorro-antd/i18n';

import { ResetPasswordSuccessProvider } from '~/shared/crm/auth/reset-password-success.provider';
import { UserService } from '~/shared/crm/user/user.service';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { routes } from './app/app.routes';
import { authConfig } from './app/config/auth.config';
import { endpointConfig } from './app/config/endpoint.config';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimations(),
    provideRouter(routes),
    importProvidersFrom(
      CrmTranslateModule.forRoot({
        locale: {
          defaultLocale: 'sk',
          locales: {
            sk: {
              ngLocale: sk,
              nzLocale: sk_SK,
              dateFnsLocale: dfnsSk,
            },
          },
        },
      }),
      CrmAuthModule.forRoot(authConfig),
      CrmEndpointModule.forRoot(endpointConfig),
      CrmEditorModule.forRoot(),
      CrmModalModule.forRoot(),
      CrmMessageModule.forRoot(),
    ),
    { provide: CrmUserService, useExisting: UserService },
    {
      provide: CrmAuthResetPasswordSuccessProviderImpl,
      useClass: ResetPasswordSuccessProvider,
    },
    { provide: CrmAppConfig, useValue: appConfig },
  ],
}).catch((err) => console.error(err));
