import { CrmAuthConfig } from 'common-module/auth';

export const authConfig: CrmAuthConfig = {
  components: {
    wrapper: {
      logoSrc: '/assets/images/boge_black.svg',
    },
    success: {
      wrapper: {
        logoSrc: '/assets/images/boge_black.svg',
      },
    },
  },
  interceptor: {
    interceptHttpCode: [401, 403],
  },
};
