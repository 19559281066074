import { inject, Injectable } from '@angular/core';
import {
  CrmAuthResetPasswordSuccessProviderImpl,
  CrmAuthSuccessConfig,
} from 'common-module/auth';
import { DeviceDetectorService } from 'ngx-device-detector';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ResetPasswordSuccessProvider extends CrmAuthResetPasswordSuccessProviderImpl {
  private dd = inject(DeviceDetectorService);

  override config$(): Observable<CrmAuthSuccessConfig> {
    return super.config$().pipe(
      map((config) => ({
        ...config,
        hideSuccessBackToLogin: this.resolveHideBackToLogin(),
      })),
    );
  }

  private resolveHideBackToLogin() {
    switch (this.dd.deviceType) {
      case 'mobile':
      case 'tablet':
        return true;
      default:
        return false;
    }
  }
}
