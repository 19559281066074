import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, Router, UrlTree } from '@angular/router';
import { CrmConfigService } from 'common-module/core';
import { CrmLayoutMenuConfig } from 'common-module/layout-v2';
import { map, Observable } from 'rxjs';

import { getUserRole } from './shared/crm/user/helpers/get-user-role';
import { User, UserPermissions } from './shared/crm/user/user';
import { UserService } from './shared/crm/user/user.service';
import { isAllowedMenuItem, MenuItem } from './shared/model/menu-item';

@Injectable({ providedIn: 'root' })
export class AppGuard {
  protected userService = inject(UserService);
  protected configService = inject(CrmConfigService);
  protected router = inject(Router);

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.userService.user$.pipe(
      map((user) => {
        const isAllowed = this.isAllowed(user, childRoute.data);

        if (!isAllowed) {
          const menuItems = (this.configService.getConfig<CrmLayoutMenuConfig>(
            'modules.layoutV2.menu',
          )?.items ?? []) as MenuItem[];

          const allowedMenuItem = menuItems.find((menuItem) => {
            return isAllowedMenuItem(menuItem, user);
          });

          if (allowedMenuItem) {
            this.router.navigate([allowedMenuItem.routeKey]);
          } else {
            this.router.navigate(['profile']);
          }
        }

        return isAllowed;
      }),
    );
  }

  protected isAllowed(user: User, data: Data): boolean {
    const { role, permission } = data;

    const permissions = user.permissions ?? {};
    const userRole = getUserRole(user);

    if (userRole === 'admin') {
      return true;
    }

    if (role) {
      if (Array.isArray(role)) {
        return role.includes(userRole);
      } else {
        return role === userRole;
      }
    }

    if (permission) {
      return permissions[permission as keyof UserPermissions]?.display ?? false;
    }

    return true;
  }
}
